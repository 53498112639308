import * as React from 'react'
import { useTranslation } from 'react-i18next'
import formStyles from '@assets/css/forms.module.css'
import { Button } from '@mui/material'
import { ControlledFormProps } from '@controls/types'
import { ConsentLabel } from '@controls/ConsentLabel'
import { FormCheckbox, FormTextField } from '@controls/Form'
import { VehicleInitialFormValues } from './types'
import euBadge from '@assets/images/form/eu-badge.svg'
import clsx from 'clsx'
import { sendGoogleAdsConversion, sendSimpleFacebookEvent } from '@api/tracking'

type Props = {
  title?: string
  btnSubmitTitle?: string
} & ControlledFormProps<VehicleInitialFormValues>

export const VehicleInitialFormInputs: React.FC<Props> = ({ title, btnSubmitTitle, control, disabledControls }) => {
  const { t } = useTranslation()

  // Google Ads conversion tracking function
  const gtag_report_conversion = (url?: string): void => {
    const callback = (): void => {
      if (typeof url !== 'undefined') {
        window.location.href = url
      }
    }

    gtag('event', 'conversion', {
      'send_to': 'AW-11067064921/A9tUCIvn8ocYENmEmJ0p',
      'event_callback': callback,
    })
  }

  const handleClick = () => {
    gtag_report_conversion()
    sendGoogleAdsConversion('A9tUCIvn8ocYENmEmJ0p')
    sendSimpleFacebookEvent('lead')
  }

  return (
    <div className={formStyles.formGroup__section}>
      {title && <h3 className={formStyles.formSubtitle}>{title}</h3>}
      <div className={formStyles.formGroup__header}>
        {t('global.forms.vehicleRegisterSearch.messages.stepOneTitle')}
      </div>
      <div className={formStyles.formGroup__fields}>
        <div className={formStyles.plateNumber__group}>
          <FormTextField
            control={control}
            name="carNumber"
            type="text"
            placeholder={t('global.forms.vehicleRegisterSearch.fields.regNumber.placeholder')}
            // TODO: Replace with a new translation key "global.forms.*"
            label={t('pages.buyback.form.fields.enter_reg_number')}
            className={clsx(formStyles.required, formStyles.plateNumber)}
            disabled={disabledControls}
          />
          <div className={formStyles.plateNumber__badge}>
            <img src={euBadge} alt="eu Badge" loading="lazy" className={formStyles.plateNumber__image} />
          </div>
        </div>
        <FormTextField
          control={control}
          name="phoneNumber"
          type="phone"
          label={t('global.forms.customer.fields.phone')}
          className={clsx(formStyles.required, formStyles.inputWhiteBg)}
          disabled={disabledControls}
        />
      </div>
      <div className={formStyles.checkbox__wrapper}>
        <FormCheckbox control={control} name="consent" label={<ConsentLabel />} disabled={disabledControls} />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          type="submit"
          //endIcon={<ArrowForwardIcon />}
          disabled={disabledControls}
          className={formStyles.nextButton}
          onClick={handleClick}
        >
          {btnSubmitTitle ? btnSubmitTitle : t('global.buttons.getQuote')}
        </Button>
      </div>
    </div>
  )
}
